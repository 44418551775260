import { onError } from "@apollo/client/link/error";
import { datadogRum } from "@datadog/browser-rum";

type Result = {
  errors: Array<{ detail: string }>;
};

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // Supress 401s and handle with a redirect
      if (extensions?.status === 401) {
        if (
          extensions?.detail &&
          (extensions.detail as string).includes("1501")
        ) {
          handleNoAccess("NOT_CUSTOMER");
        } else {
          handleNoAccess();
        }
        return;
      }

      datadogRum.addError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
});

const handleNoAccess = (message?: "NOT_CUSTOMER") => {
  if (!window.location.pathname.startsWith("/no_access")) {
    window.location.replace(
      "/no_access" + (message ? `?message=${message}` : "")
    );
  }
};
